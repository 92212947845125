
import PortionSvg from '@/assets/Portion.svg.vue'
import ArrowsSvg from '@/assets/viewer/Arrows.svg.vue'
import BoxSvg from '@/assets/viewer/Box.svg.vue'
import EyeSvg from '@/assets/viewer/Eye.svg.vue'
import HistorySvg from '@/assets/viewer/History.svg.vue'
import FloatingTabs from '@/components/FloatingTabs/FloatingTabs.vue'
import CubeLoader from '@/components/Loaders/CubeLoader.vue'
import {
  HistoryItem,
  createHistoryItem,
  deleteHistoryItem,
  downloadHistoryItem,
  editHistoryItem,
  historyStore,
  resetHistoryError,
  restoreHistoryItem,
} from '@/modules/history'
import { SteelspaceModel, modelStore, createModelPreview } from '@/modules/model'
import {
  cameraMode,
  clearView,
  deleteLabels,
  drawModelView,
  isometricCamera,
  modelViewerStore,
  removeActiveLoad,
  resetCamera,
  setCameraMode,
  setRenderContext,
  toggleLabels,
  toggleXray,
} from '@/store/modelViewer.store'
import { FloatingLabel } from '@/types'
import { CsBreadcrumbs, CsBtn, CsBtnToggle, CsFlex } from '@consteel/csuetify'
import { RenderContext } from '@consteel/straw'
import { User, getAuth } from 'firebase/auth'
import Vue from 'vue'
import DisplayTab from './DisplayTab.vue'
import FailedToLoadModel from './FailedToLoadModel.vue'
import FilterTab from './FilterTab.vue'
import HistoryTab from './HistoryTab.vue'
import {
  DefaultLabel,
  LineLoadLabel,
  LoadTransferSurfaceLabel,
  NodalLoadLabel,
  StructuralMemberLabel,
  StructuralPlateLabel,
  SupportPointLabel,
  SurfaceLoadLabel,
} from './Labels'
import LoadTab from './LoadTab.vue'
import PortionTab from './PortionTab.vue'

export default Vue.extend({
  name: 'ModelViewerTab',
  components: {
    CubeLoader,
    CsBreadcrumbs,
    CsBtn,
    CsBtnToggle,
    CsFlex,
    DefaultLabel,
    FilterTab,
    FloatingTabs,
    LineLoadLabel,
    LoadTransferSurfaceLabel,
    NodalLoadLabel,
    StructuralMemberLabel,
    StructuralPlateLabel,
    SupportPointLabel,
    SurfaceLoadLabel,
    HistoryTab,
    FailedToLoadModel,
  },
  props: {
    breadcrumbItems: {
      type: Array as () => Array<{
        text: string
        disabled: boolean
      }>,
    },
    floatingLabels: {
      type: Array as () => FloatingLabel[],
    },
    activeLoadId: String,
    model: Object as () => SteelspaceModel | null,
    historyItems: Array as () => HistoryItem[],
  },
  data() {
    return {
      selectedHistoryItemId: null as string | null,
      secondarySelectedHistoryItemId: null as string | null,
      labelId: 0,
      compareVersionIsActive: false,
    }
  },
  computed: {
    historyModifyDisabled(): boolean {
      return (
        this.currModelIsPublic ||
        (this.model?.originUserData.email !== this.currentUser?.email &&
          !this.model?.canDownloadModel.find(
            (userData) => userData.email === this.currentUser?.email
          ))
      )
    },
    currentUser(): User | null {
      return getAuth().currentUser
    },
    labelVisibility(): boolean {
      return modelViewerStore.labelVisibility
    },
    viewControlVisibility(): boolean {
      return modelViewerStore.viewControlVisibility
    },
    cantLoad(): boolean {
      return modelViewerStore.cantLoad
    },
    errorMessage(): string {
      return modelViewerStore.errorMessage
    },
    loading(): boolean {
      return historyStore.loading || modelStore.loading
    },
    modelLoading(): boolean {
      return modelViewerStore.currentViewLoading
    },
    currModelIsPublic(): boolean {
      return !!this.model?.isPublic
    },
    currModelIsShared(): boolean {
      return !this.model?.isOrigin
    },
    selectedItem(): HistoryItem | SteelspaceModel | undefined | null {
      const historyItem = this.historyItems.find((item) => item.id === this.selectedHistoryItemId)

      if (historyItem) {
        return historyItem
      }

      return this.model
    },
    selectedCameraMode: {
      get(): cameraMode {
        return modelViewerStore.cameraMode
      },
      set(newVal: cameraMode) {
        setCameraMode(newVal)
      },
    },
    secondaryTabs(): Array<Record<string, unknown>> {
      const tabs = [
        {
          icon: HistorySvg,
          text: this.$t('History'),
          content: HistoryTab,
          disabled: this.loading,
          props: {
            value: this.selectedHistoryItemId,
            secondarySelected: this.secondarySelectedHistoryItemId,
            model: this.model,
            items: this.historyItems,
            errorMessage: historyStore.historyErrorMessage,
            errorTitle: historyStore.historyErrorTitle,
            errorIcon: historyStore.historyErrorIcon,
            errorIconColor: historyStore.historyErrorIconColor,
            loading: this.loading,
            hideEdit: this.historyModifyDisabled,
            hideCreate: this.historyModifyDisabled,
            hideRestore: this.historyModifyDisabled,
            hideDelete: this.historyModifyDisabled,
            selectable: true,
            selectToCompare: this.compareVersionIsActive,
            compareVersionIsActive: this.compareVersionIsActive,
          },
          listeners: {
            input: this.handleHistoryItemSelect,
            download: downloadHistoryItem,
            restore: restoreHistoryItem,
            create: this.handleHistoryItemCreate,
            edit: editHistoryItem,
            errorCancel: resetHistoryError,
            clickCompareVersions: this.handleClickCompareVersions,
            selectSecondary: this.handleHistoryItemSelectSecondary,
            delete: deleteHistoryItem,
          },
        },
        {
          icon: BoxSvg,
          text: this.$t('Selection'),
          content: FilterTab,
          disabled: this.loading,
        },
        {
          icon: ArrowsSvg,
          text: this.$t('Loads'),
          content: LoadTab,
          disabled: this.loading,
        },
        {
          icon: EyeSvg,
          text: this.$t('Display'),
          content: DisplayTab,
          disabled: this.loading || this.modelLoading,
          listeners: {
            toggleLabels,
          },
        },
        {
          icon: PortionSvg,
          text: this.$t('Portions'),
          content: PortionTab,
          disabled: this.loading || this.modelLoading,
        },
      ]

      return tabs
    },
  },
  methods: {
    async handleHistoryItemSelectSecondary(historyItemId: string): Promise<void> {
      this.secondarySelectedHistoryItemId = historyItemId
      this.$emit('navigateToCompare', {
        current: this.selectedHistoryItemId,
        incoming: historyItemId,
      })
      this.compareVersionIsActive = false
    },
    handleClickCompareVersions(): void {
      if (this.compareVersionIsActive) {
        this.selectedHistoryItemId = this.model?.id || null
        this.secondarySelectedHistoryItemId = null
      } else {
        this.secondarySelectedHistoryItemId = null
      }
      this.compareVersionIsActive = !this.compareVersionIsActive
    },
    async loadItem(): Promise<void> {
      removeActiveLoad()
      this.$emit('loadAndRenderItem', {
        id: this.selectedHistoryItemId,
        drawCallback: async () => {
          await drawModelView()

          if (this.model && this.model.previewStoragePath === '') {
            await createModelPreview(this.model)
          }
        },
      })
    },
    async handleHistoryItemSelect(historyItemId: string): Promise<void> {
      this.selectedHistoryItemId = historyItemId
      this.$emit('historyItemSelect', historyItemId)
    },
    async handleHistoryItemCreate({
      title,
      description,
    }: {
      title: string
      description: string
    }): Promise<void> {
      await createHistoryItem(this.model as SteelspaceModel, title, description)
    },

    toggleXray,
    resetCamera,
    isometricCamera,
    setFocusToCanvas() {
      if (!modelViewerStore.cantLoad) {
        ;(this.$refs.canvas as HTMLCanvasElement).focus()
      }
    },
  },
  mounted(): void {
    const context = new RenderContext(
      this.$refs.canvas as HTMLCanvasElement,
      this.$refs.canvasParent as HTMLDivElement
    )
    context.RenderGizmo()
    setRenderContext(context)
  },
  created(): void {
    this.selectedHistoryItemId = this.$route.params.historyId || this.$route.params.modelId || null
  },
  watch: {
    async selectedHistoryItemId() {
      clearView()
      deleteLabels()
      await this.loadItem()

      this.$emit('input', this.selectedItem)
    },
    async model(newModel: SteelspaceModel, oldModel: SteelspaceModel) {
      if (
        newModel?.id === oldModel?.id &&
        this.selectedHistoryItemId === this.model?.id &&
        newModel.checksum !== oldModel.checksum
      ) {
        await this.loadItem()
      }
    },
  },
})
